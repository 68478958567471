import React from 'react';
import PropTypes from 'prop-types';

export function DonationReviewTitle() {
  return (
    <div className="text-3xl text-center sm:text-start font-extraBold md:text-5xl">
      Loved by <span className="text-primary">you</span>
    </div>
  );
}

export function AppReviewTitle() {
  return (
    <div className="text-3xl text-center sm:text-start font-extraBold md:text-5xl">
      What Our <span className="text-primary">Users</span> Had To Say?
    </div>
  );
}

DonationReviewTitle.propTypes = {
  children: PropTypes.node,
};

AppReviewTitle.propTypes = {
  children: PropTypes.node,
};
