import React from 'react';
import PropTypes from 'prop-types';
import BoldTitle from './BoldTitle';

const AppFeatures = ({ features }) => {
  return (
    <div className="w-full bg-neutral-1">
      <div className="w-full max-w-6xl px-6 py-16 m-auto md:py-24">
        <BoldTitle className="leading-snug">Features</BoldTitle>
        <div className="grid w-full grid-cols-1 gap-6 my-8 md:gap-8 md:my-16 md:grid-cols-2">
          {features.map((item) => (
            <div
              key={item.id}
              className="p-6 space-y-2 border bg-neutral border-primary-1 rounded-2xl"
            >
              <h1 className="font-bold">{item.title}</h1>
              <p className="text-sm">{item.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

AppFeatures.propTypes = {
  features: PropTypes.array,
};

export default AppFeatures;
