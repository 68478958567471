import React from 'react';
import PropTypes from 'prop-types';
import BoldTitle from './BoldTitle';
import { Image } from './image';
import { formatStringWithDoubleLineBreaks } from '../utils';
import IconComponent from './IconComponent';

const FeatureDescription = ({ icon, title, description }) => (
  <div className="mx-auto mt-6 lg:mr-16 lg:col-span-1 lg:mx-0 max-w-52 lg:w-auto">
    <div className="flex w-12 h-12 mx-auto rounded-full bg-primary-1 lg:mx-0">
      <IconComponent name={icon} className="m-auto" />
    </div>
    <div className="mt-4 mb-2 font-semiBold">{title}</div>
    <div className="text-sm max-w-52 font-regular overflow-ellipsis">
      {formatStringWithDoubleLineBreaks(description)}
    </div>
  </div>
);

FeatureDescription.propTypes = {
  icon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

const ImageBlock = ({ imageFile, imageAlt }) => (
  <div className="mt-10 lg:mt-0 lg:col-span-2">
    <Image alt={imageAlt} imageFile={imageFile} className="mx-auto lg:mx-0" />
  </div>
);

ImageBlock.propTypes = {
  imageFile: PropTypes.object.isRequired,
  imageAlt: PropTypes.string.isRequired,
};

const FeatureBlock = ({ features, showTitle }) => (
  <div className="text-center lg:col-span-2 lg:text-left">
    {showTitle && <BoldTitle>Here You Can</BoldTitle>}
    <div className="lg:grid lg:grid-cols-2">
      {features.slice(0, 4).map((feature, index) => (
        <FeatureDescription key={index} {...feature} />
      ))}
    </div>
  </div>
);

FeatureBlock.propTypes = {
  features: PropTypes.array.isRequired,
  showTitle: PropTypes.bool,
};

const WhatYouGetSection = ({ index, imageFile, features, imageAlt }) => {
  const isEvenIndex = index % 2 === 0;
  const showTitle = index === 0;

  return (
    <div className={`w-full ${isEvenIndex ? 'bg-neutral' : 'bg-neutral-1'}`}>
      <div className="w-full max-w-6xl px-6 py-16 m-auto lg:grid lg:grid-cols-4 md:py-24">
        {isEvenIndex ? (
          <>
            <div className="hidden lg:block lg:col-span-2">
              <ImageBlock imageFile={imageFile} imageAlt={imageAlt} />
            </div>
            <FeatureBlock features={features} showTitle={showTitle} />
            <div className="block lg:hidden lg:col-span-2">
              <ImageBlock imageFile={imageFile} imageAlt={imageAlt} />
            </div>
          </>
        ) : (
          <>
            <FeatureBlock features={features} />
            <ImageBlock imageFile={imageFile} imageAlt={imageAlt} />
          </>
        )}
      </div>
    </div>
  );
};

WhatYouGetSection.propTypes = {
  imageAlt: PropTypes.string.isRequired,
  imageFile: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  features: PropTypes.array.isRequired,
};

export default WhatYouGetSection;
