import React from 'react';
import PropTypes from 'prop-types';

import { appStoreNames } from '../constants';
import FeaturedStoreLinkButton from './FeaturedStoreLinkButton';
import { Image } from './image';
import { extractFileName } from '../utils';
import IconComponent from './IconComponent';

const DesktopOrWebAppLinks = ({ desktopLinks, webLinks, image, imageFile }) => {
  const linksToUse =
    desktopLinks.windows || desktopLinks.mac ? desktopLinks : webLinks;
  return (
    <div className="h-full p-4 shadow-xl md:p-6 bg-neutral md:rounded-3xl rounded-2xl">
      <div className="p-3 rounded-lg md:p-4 md:rounded-2xl bg-primary-2 w-max">
        <IconComponent
          name={linksToUse === desktopLinks ? 'desktop-icon' : 'web-page-icon'}
          className="text-primary"
        />
      </div>
      <div className="grid grid-cols-1 gap-10 md:grid-cols-2 md:gap-0">
        <div>
          <p className="mt-2 font-bold md:text-3xl text-neutral-7">
            {linksToUse === desktopLinks ? 'Desktop App' : 'Web App'}
          </p>
          <div className="grid grid-cols-2 gap-2 mt-4 md:gap-4 md:mt-10">
            {appStoreNames.map(
              (item) =>
                linksToUse[item.slug] && (
                  <div key={item.slug}>
                    <FeaturedStoreLinkButton
                      className="w-full"
                      icon={item.icon}
                      title={
                        linksToUse === desktopLinks
                          ? 'Download for'
                          : item.secondaryLabel
                      }
                      link={{
                        label:
                          linksToUse === desktopLinks
                            ? item.secondaryLabel
                            : item.label,
                        url: linksToUse[item.slug],
                      }}
                    />
                  </div>
                ),
            )}
          </div>
        </div>
        <div>
          <Image
            alt={extractFileName(image)}
            imageFile={imageFile}
            className="m-auto"
            quality={100}
          />
        </div>
      </div>
    </div>
  );
};

DesktopOrWebAppLinks.propTypes = {
  desktopLinks: PropTypes.object.isRequired,
  webLinks: PropTypes.object.isRequired,
  image: PropTypes.string.isRequired,
  imageFile: PropTypes.object.isRequired,
};

export default DesktopOrWebAppLinks;
