import React from 'react';
import PropTypes from 'prop-types';
import CardSliderComponent, {
  CardSlider,
  ControlsWrapper,
  NavigationButtons,
  SliderCard,
  SliderContent,
  SliderTitle,
} from './CardSliderComponent';
import IconComponent from './IconComponent';

function Reviews({ reviews, children }) {
  const getAvatar = (reviewItem) => {
    return (
      <div className="flex items-center justify-center w-8 h-8 mr-4 rounded-full bg-primary font-regular text-neutral">
        {reviewItem.avatar ? (
          <img
            src={reviewItem.avatar}
            className="w-8 h-8"
            alt={`profile avatar ${reviewItem.reviewer[0]}`}
          />
        ) : (
          reviewItem.reviewer[0].toUpperCase()
        )}
      </div>
    );
  };

  const getReviewerAndRating = (reviewItem) => (
    <div>
      <div className="text-sm font-semiBold">{reviewItem.reviewer}</div>
      <div className="flex">
        {[...Array(reviewItem.stars).keys(reviewItem.stars)].map((item) => (
          <IconComponent
            name="star-gold"
            key={item}
            className="w-4 h-4 text-yellow-5"
          />
        ))}
      </div>
    </div>
  );

  const isArabic = (text) => /[\u0600-\u06FF]/.test(text);
  const renderReviewText = (text) => {
    const parts = text.split(/([\u0600-\u06FF]+)/);
    return parts.map((part, index) => (
      <span
        key={index}
        className={isArabic(part) ? 'font-arabic' : 'font-regular'}
      >
        {part}
      </span>
    ));
  };
  return (
    <section>
      <div className="hidden sm:block">
        <CardSliderComponent items={reviews} title="Our Journey">
          <SliderTitle>{children}</SliderTitle>
          <SliderContent>
            <CardSlider>
              {reviews.map((item) => (
                <SliderCard key={item.id}>
                  <div>
                    <div className="flex">
                      {getAvatar(item)}
                      {getReviewerAndRating(item)}
                    </div>
                    <div className="mt-4 text-sm font-regular">
                      {renderReviewText(item.reviewText)}
                    </div>
                  </div>
                </SliderCard>
              ))}
            </CardSlider>
            <ControlsWrapper hasIndicator={false}>
              <NavigationButtons />
            </ControlsWrapper>
          </SliderContent>
        </CardSliderComponent>
      </div>

      <div className="block sm:hidden">
        <div className="w-full max-w-6xl px-6 py-16 m-auto space-y-16 md:py-24">
          <div className="space-y-4">{children}</div>
          <div className="space-y-6">
            {reviews.map((item) => (
              <div key={item.id} className="p-6 shadow rounded-2xl bg-neutral">
                <div className="flex">
                  {getAvatar(item)}
                  {getReviewerAndRating(item)}
                </div>
                <div className="mt-4 text-sm font-regular">
                  {renderReviewText(item.reviewText)}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

Reviews.propTypes = {
  reviews: PropTypes.array.isRequired,
  children: PropTypes.node,
};

export default Reviews;
