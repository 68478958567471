import React, { useState, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import IconComponent from './IconComponent';

const SliderContext = createContext();

export const SliderCard = ({ children, isActive }) => {
  return (
    <div className="flex w-full h-full">
      <div
        className={`flex flex-col w-full h-full gap-4 p-5 border-2 shadow-xl lg:flex-row items-start rounded-2xl transition-all duration-300 mr-6 sm:mr-8 ${
          isActive ? 'border-neutral md:border-primary' : 'border-neutral'
        }`}
      >
        {children}
      </div>
    </div>
  );
};

SliderCard.propTypes = {
  children: PropTypes.node.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export const CardSlider = ({ children }) => {
  const { currentIndex } = useContext(SliderContext);
  return (
    <div className="relative w-full h-full pl-6 sm:pl-0 sm:w-1/2">
      <div className="h-full">
        <motion.div
          className="grid grid-flow-col auto-cols-[100%] h-full"
          animate={{
            translateX: -currentIndex * 100 + '%',
          }}
          transition={{ type: 'spring', stiffness: 300, damping: 30 }}
        >
          {React.Children.map(children, (child, index) => (
            <div className="h-full">
              {React.cloneElement(child, { isActive: index === currentIndex })}
            </div>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

CardSlider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const CardIndicator = ({ items }) => {
  const { currentIndex } = useContext(SliderContext);
  return (
    <div className="h-full">
      <motion.div
        className="flex w-full"
        animate={{
          translateX: -currentIndex * 55 + '%',
        }}
      >
        {items.map((item, idx) => (
          <motion.div
            key={item.id || idx}
            className="text-center"
            animate={{
              minWidth: currentIndex === idx ? '100%' : '55%',
            }}
            transition={{ type: 'spring', stiffness: 300, damping: 30 }}
          >
            <motion.p
              className={`${
                currentIndex === idx
                  ? 'font-semibold text-primary'
                  : 'text-neutral-2'
              } transition-all duration-200 ease-in-out text-3xl md:text-5xl`}
              key={item.id || idx}
            >
              {item.year || item.indicatorValue || item.id || ''}
            </motion.p>
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
};

CardIndicator.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      indicatorValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
};

export const NavigationButtons = () => {
  const { currentIndex, setCurrentIndex, items } = useContext(SliderContext);

  const createButton = (direction) => {
    const isLeft = direction === 'left';
    const icon = isLeft ? 'pagination-arrow-left' : 'pagination-arrow-right';
    const positionClass = isLeft ? 'left-0' : 'right-0';
    const disabled = isLeft
      ? currentIndex === 0
      : currentIndex === items.length - 1;

    const handleClick = () => {
      if (isLeft) {
        setCurrentIndex((prev) => Math.max(0, prev - 1));
      } else {
        setCurrentIndex((prev) => Math.min(items.length - 1, prev + 1));
      }
    };

    return (
      <button
        type="button"
        className={`absolute ${positionClass} flex items-center justify-center w-8 h-8 transform -translate-y-1/2 border rounded-full sm:w-12 sm:h-12 top-1/2 ${
          disabled ? 'bg-neutral-2' : 'bg-neutral-9 hover:bg-neutral-8'
        }`}
        disabled={disabled}
        onClick={handleClick}
      >
        <IconComponent name={icon} className="text-neutral" />
      </button>
    );
  };

  return (
    <div className="flex justify-between text-neutral-0">
      {createButton('left')}
      {createButton('right')}
    </div>
  );
};

export const ControlsWrapper = ({ children, hasIndicator }) => {
  return (
    <div
      className={`relative  m-auto mt-12 sm:mx-0 ${
        hasIndicator ? 'w-40 sm:w-60' : 'sm:w-32'
      }`}
    >
      {children}
    </div>
  );
};

ControlsWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  hasIndicator: PropTypes.bool,
};

export const SliderTitle = ({ children }) => {
  return <div>{children}</div>;
};

SliderTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

export const SliderContent = ({ children }) => {
  return <div className="mt-8 md:mt-16">{children}</div>;
};

SliderContent.propTypes = {
  children: PropTypes.node.isRequired,
};

const CardSliderComponent = ({ children, items }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <SliderContext.Provider value={{ currentIndex, setCurrentIndex, items }}>
      <div className="w-full overflow-hidden">
        <div className="w-full max-w-6xl py-16 m-auto sm:px-6 md:py-24">
          {children}
        </div>
      </div>
    </SliderContext.Provider>
  );
};

CardSliderComponent.propTypes = {
  children: PropTypes.node.isRequired,
  items: PropTypes.array.isRequired,
};

export default CardSliderComponent;
