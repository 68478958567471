import React from 'react';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from '../../components/layout';
import AppTile from '../../components/AppTile';
import Reviews from '../../components/Reviews';
import AllDownloadLinks from '../../components/AllDownloadLinks';
import OtherApps from '../../components/OtherApps';
import { extractFileName } from '../../utils';
import { CommonContext } from '../../context';
import AppTitleFullWidth from '../../components/AppTitleFullWidth';
import WhatYouGetSection from '../../components/WhatYouGetSection';
import AppFeatures from '../../components/AppFeatures';
import { ChevronRight } from '../../custom-icons';
import { AppReviewTitle } from '../../components/ReviewTitle';

export const query = graphql`
  query ($route: String!) {
    allAppList(filter: { route: { eq: $route } }) {
      nodes {
        isFeatured
        layout
        appName
        description
        font
        theme
        heroImage
        heroImageFile {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH, quality: 100)
          }
        }
        desktopDownloadPreviewImage
        desktopDownloadPreviewImageFile {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        videoEmbedUrl
        ogImage
        route
        downloadLinksTitle
        downloadLinks {
          playStore
          huawei
          appStore
          mac
          windows
          web
          linux
        }
        whatYouGet {
          image
          imageFile {
            childImageSharp {
              gatsbyImageData(
                width: 700
                placeholder: NONE
                layout: CONSTRAINED
              )
            }
          }
          features {
            icon
            description
            title
          }
        }
        reviews {
          reviewer
          stars
          reviewText
          avatar
        }
        features {
          title
          description
        }
        seoKeywords
      }
    }
  }
`;

function SingleAppPage({ data, location }) {
  const appData = data.allAppList.nodes[0];
  appData.imageFile = appData.heroImageFile;

  return (
    <CommonContext.Provider value={{ location }}>
      <Layout
        title={appData.appName}
        description={appData.description.replace(/<\w+\/?>/i, ' ')}
        keywords={appData.seoKeywords}
        image={`/images/${appData.ogImage}`}
        location={location}
      >
        {appData.layout === 'fullwidth' ? (
          <AppTitleFullWidth appData={appData} />
        ) : (
          <AppTile index={0} appData={appData} isSingleAppPage={true} />
        )}
        {appData.layout === 'fullwidth' && (
          <AllDownloadLinks
            title={appData.downloadLinksTitle}
            downloadLinks={appData.downloadLinks}
            desktopDownloadPreviewImage={appData.desktopDownloadPreviewImage}
            desktopDownloadPreviewImageFile={
              appData.desktopDownloadPreviewImageFile
            }
          />
        )}

        {appData.whatYouGet.map((item, index) => (
          <WhatYouGetSection
            imageAlt={extractFileName(item.image)}
            imageFile={item.imageFile}
            features={item.features}
            index={index}
            key={index}
          />
        ))}
        {appData.reviews.length ? (
          <Reviews reviews={appData.reviews}>
            {' '}
            <AppReviewTitle />
          </Reviews>
        ) : null}
        {appData.features.length ? (
          <AppFeatures features={appData.features} />
        ) : null}

        <OtherApps currentAppName={appData.appName} />
        <Link to="/apps">
          <div className="hidden mb-12 text-primary text-md font-semiBold md:flex md:justify-center">
            <span className="link-underline-primary">Visit All Projects</span>
            <ChevronRight className="text-secondary" />
          </div>
        </Link>
      </Layout>
    </CommonContext.Provider>
  );
}

SingleAppPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export default SingleAppPage;
